import call from "@kinetics254/cassandra-base/service/http";
import constants from "./constants";
import { AuthService } from "@kinetics254/cassandra-base/modules/auth";
const user = AuthService.user;
const caption = user
  ? user.captions
    ? user.captions.pettycash_caption
    : undefined
  : undefined;

export default {
  namespaced: true,
  state: {
    cancelModal: false,
    pettycashbank: [],
    pettycash: [],
    loader: false,
    pettycashedit: [],
    expenseCodes: [],
    dimensions: [],
    pettycashDocument: "",
    destinations: [],
    transportRates: [],
    speedKeys: [],
    funds: [],
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
  },
  getters: {
    pettycashstate: (state) => (value) => state[value],
  },
  actions: {
    openCancelModal: ({ commit }, data) => {
      commit("MUTATE", { state: "cancelModal", data });
    },

    submitPettyCash: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.pettycash, data)
        .then((res) => {
          // redirect back to pettycash
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit(
            "ApiSuccess",
            res.data.message + `: ${caption ? caption : "Petty Cash"} Submitted`
          );
          Event.$emit("back");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    getExpenseCodes: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.expenseCodes)
        .then((res) => {
          commit("MUTATE", { state: "expenseCodes", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    editPettyCash: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.updatePettycash, data)
        .then((res) => {
          // redirect back to pettycash
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit(
            "ApiSuccess",
            res.data.message + `: ${caption ? caption : "Petty Cash"} Updated`
          );
          Event.$emit("back");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    deletePettyCash: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("delete", constants.deletePettyCash(data), data)
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit(
            "ApiSuccess",
            res.data.message + `: ${caption ? caption : "Petty Cash"} deleted`
          );
          Event.$emit("back");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    getPettyCash: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.pettycash)
        .then((res) => {
          commit("MUTATE", { state: "pettycash", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    getPettyCashBank: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.pettycashbank)
        .then((res) => {
          commit("MUTATE", { data: res.data.data, state: "pettycashbank" });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    getByDocumentNo: ({ commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.getByDocument(data))
        .then((res) => {
          commit("MUTATE", { data: res.data.data, state: "pettycashedit" });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    /**
     * Get dimension values
     * @param commit
     */
    getDimensionValues: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.dimensions)
        .then((res) => {
          commit("MUTATE", { state: "dimensions", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    sendForApproval: ({ commit, dispatch }, data) => {
      console.log(data);
      call("post", constants.sendForApproval, data)
        .then((res) => {
          Event.$emit(
            "ApiSuccess",
            `${caption ? caption : "Petty Cash"} successfully sent for approval`
          );
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("back");
        })
        .catch((error) => {
          Event.$emit(
            "ApiError",
            `Unable to send Record For Approval: ${error.response.data.message}`
          );
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    cancelApprovalRequest: ({ dispatch, commit }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.cancelApprovalRequest, data)
        .then((res) => {
          Event.$emit("ApiSuccess", "Approval Request Cancelled");
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("back");
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },

    saveAndSendforApproval: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call(
        "post",
        data.method === "post"
          ? constants.pettycash
          : constants.updatePettycash,
        data
      )
        .then(() => {
          Event.$emit(
            "ApiSuccess",
            data.method === "post"
              ? `You have successfully created a new ${
                  caption ? caption : "Petty Cash"
                } request`
              : `You have successfully Updated ${data.no} ${
                  caption ? caption : "Petty Cash"
                } Record`
          );
          commit("loader/SET_LOADING", false, { root: true });
          dispatch("sendForApproval", { headerNo: data.no });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    getPettyCashDocument: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.pettyCashDocument(data))
        .then((res) => {
          commit("loader/SET_LOADING", false, { root: true });
          commit("MUTATE", {
            state: "pettycashDocument",
            data: res.data.data,
          });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    getDestinations: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.DESTINATIONS)
        .then((res) => {
          commit("MUTATE", { state: "destinations", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },

    getTransportRates: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.TRANSPORT_RATES)
        .then((res) => {
          commit("MUTATE", { state: "transportRates", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data);
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getfunds: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.funds)
        .then((res) => {
          commit("MUTATE", { state: "funds", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch(() => {
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    getdimensionSpeedKey: ({ commit }) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("get", constants.dimensionSpeedKey)
        .then((res) => {
          commit("MUTATE", { state: "speedKeys", data: res.data.data });
          commit("loader/SET_LOADING", false, { root: true });
        })
        .catch(() => {
          commit("loader/SET_LOADING", false, { root: true });
        });
    },
    deletePettyCashLines: ({ commit, dispatch }, data) => {
      commit("loader/SET_LOADING", true, { root: true });
      call("post", constants.deleteLines, data)
        .then((res) => {
          Event.$emit(
            "ApiSuccess",
            `${caption ? caption : "Petty Cash"} Item Deleted`
          );
          commit("loader/SET_LOADING", false, { root: true });
          dispatch("getByDocumentNo", data.document_no);
        })
        .catch((error) => {
          commit("loader/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
  },
};
